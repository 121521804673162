<template>
  <div class="p-0 no-scroll-x">
    <div class="jumbotron jumbotron-fluid text-white mb-0" :style="{ backgroundImage: 'url(' + landingBackground + ')', backgroundSize: 'cover' }">
      <div class="container animated-section">
        <div class="row py-5">
          <div class="col-md-5 text-center">
            <img :src="renderImage" alt="Left" class="img-fluid responsive-image">
          </div>
          <div class="col-md-7 d-flex py-4 px-1">
            <div>
              <h1 class="jumbotron-header-text">Belajar Solidworks</h1>
              <h1 class="jumbotron-header-text">dari Nol sampai <i>Advance</i> </h1>
              <h1 class="jumbotron-header-text">Bersertifikat Internasional</h1>
              <p class="mt-3 jumbotron-lead-text">
                Bootcamp design engineering end to end mulai dari CAD 2D & 3D sampai dengan CAE. Belajar AutoCAD, SolidWorks, dan ANSYS jadi mudah dengan <strong>“Project Based Learning”</strong>.
              </p>
              <b-button size="lg" class="mt-1 btn-custom rounded-pill" href="https://mechtechidn.mayar.link/">
                <h1 class="text-white jumbotron-button-text">Daftar Sekarang</h1>
              </b-button>
            </div>
          </div>
        </div>
      </div>
    </div>

    <b-row class="text-center animated-section mt-5">
      <b-col>
        <h1 class="heading-text-underline">Alumni Kami Tersebar di Berbagai Instansi</h1>
      </b-col>
    </b-row>
    <b-row class="d-none d-md-flex d-lg-flex">
      <b-col lg="5" sm="5" md="5" />
      <b-col lg="2" sm="2" md="2">
        <hr class="institution-line mt-1">
      </b-col>
      <b-col lg="5" sm="5" md="5" />
    </b-row>
    <b-row class="d-sm-flex d-md-none d-lg-none">
      <b-col cols="4" />
      <b-col cols="4">
        <hr class="institution-line mt-1">
      </b-col>
      <b-col cols="4" />
    </b-row>
    <b-row class="mx-5 mt-5">
      <b-col class="animated-section">
        <VueSlickCarousel v-bind="uniCarouselSetting">
          <b-img v-for="univ in univLogoSr" :key="univ" :src="univ" class="w-75 pl-2" />
        </VueSlickCarousel>
      </b-col>
    </b-row>

    <b-row class="mt-5 text-center pt-5">
      <b-col class="px-0">
        <h1 class="heading-text-underline">Mitra Afiliasi</h1>
      </b-col>
    </b-row>
    <b-row class="d-none d-md-flex d-lg-flex">
      <b-col lg="5" sm="5" md="5" />
      <b-col lg="2" sm="2" md="2">
        <b-row>
          <b-col lg="3" sm="5" md="5" />
          <b-col lg="6" sm="2" md="2">
            <hr class="institution-line mt-1">
          </b-col>
          <b-col lg="3" sm="5" md="5" />
        </b-row>
      </b-col>
      <b-col lg="5" sm="5" md="5" />
    </b-row>
    <b-row class="d-sm-flex d-md-none d-lg-none">
      <b-col cols="4" />
      <b-col cols="4">
        <hr class="institution-line mt-1 mx-2">
      </b-col>
      <b-col cols="4" />
    </b-row>
    <b-row class="mx-3 mt-5">
      <b-col class="animated-section">
        <VueSlickCarousel v-bind="partnerCarouselSetting">
          <div v-for="partner in partnerLogoSrc" :key="partner">
            <b-card class="mx-1">
              <b-img fluid :src="partner" class="partner-images" />
            </b-card>
          </div>
        </VueSlickCarousel>
      </b-col>
    </b-row>

    <b-row class="mt-5 animated-section top-gradient animated-section">
      <b-col class="px-2">
        <div class="container mt-4 text-center">
          <h1 class="heading-text-white mt-4 mb-0 py-2">Kenapa Kamu Harus Memilih Kami?</h1>
        </div>

        <b-row class="d-none d-md-flex d-lg-flex">
          <b-col class="text-center">
            <b-img :src="valuePropositionSrc[0]" class="w-25" />
            <p class="value-proposition-text mt-3">2 Sertifikat Internasional <br> langsung dari SolidWorks</p>
          </b-col>
          <b-col class="text-center">
            <b-img :src="valuePropositionSrc[1]" class="w-25" />
            <p class="value-proposition-text mt-3">Jaminan 100% Lulus Sertifikasi <br> (Remedial Gratis jika Gagal)</p>
          </b-col>
          <b-col class="text-center">
            <b-img :src="valuePropositionSrc[2]" class="w-25" />
            <p class="value-proposition-text mt-3">Pendampingan saat Sertifikasi</p>
          </b-col>
        </b-row>
        <b-row class="mt-4 d-none d-md-flex d-lg-flex">
          <b-col class="text-center">
            <b-img :src="valuePropositionSrc[3]" class="w-25" />
            <p class="value-proposition-text mt-3">1 on 1 mentoring <br> di luar kelas</p>
          </b-col>
          <b-col class="text-center">
            <b-img :src="valuePropositionSrc[4]" class="w-25" />
            <p class="value-proposition-text mt-3">Project Based Learning</p>
          </b-col>
          <b-col class="text-center">
            <b-img :src="valuePropositionSrc[5]" class="w-25" />
            <p class="value-proposition-text mt-3">Kesempatan mendapatkan income tambahan dari Freelance Class</p>
          </b-col>
        </b-row>

        <b-row class="d-sm-flex d-md-none d-lg-none">
          <b-col class="text-center">
            <b-img :src="valuePropositionSrc[0]" class="w-50" />
            <p class="value-proposition-text mt-3">2 Sertifikat Internasional langsung dari SolidWorks</p>
          </b-col>
          <b-col class="text-center">
            <b-img :src="valuePropositionSrc[1]" class="w-50" />
            <p class="value-proposition-text mt-3">Jaminan 100% Lulus Sertifikasi (Remedial Gratis jika Gagal)</p>
          </b-col>
        </b-row>
        <b-row class="mt-4 d-sm-flex d-md-none d-lg-none">
          <b-col class="text-center">
            <b-img :src="valuePropositionSrc[2]" class="w-50" />
            <p class="value-proposition-text mt-3">Pendampingan saat Sertifikasi</p>
          </b-col>
          <b-col class="text-center">
            <b-img :src="valuePropositionSrc[3]" class="w-50" />
            <p class="value-proposition-text mt-3">1 on 1 mentoring <br> di luar kelas</p>
          </b-col>
        </b-row>
        <b-row class="mt-4 d-sm-flex d-md-none d-lg-none">
          <b-col class="text-center">
            <b-img :src="valuePropositionSrc[4]" class="w-50" />
            <p class="value-proposition-text mt-3">Project Based Learning</p>
          </b-col>
          <b-col class="text-center">
            <b-img :src="valuePropositionSrc[5]" class="w-50" />
            <p class="value-proposition-text mt-3">Kesempatan mendapatkan income tambahan dari Freelance Class</p>
          </b-col>
        </b-row>

        <b-row class="my-5 pt-5">
          <b-col class="d-flex justify-content-center">
            <h1 class="heading-text-white mb-0 py-2">Portofolio</h1>
          </b-col>
        </b-row>

        <b-row class="mb-5 pb-5 mx-2">
          <b-col>
            <VueSlickCarousel v-bind="portoCarouselSetting">
              <div v-for="porto in portoSrc" :key="porto">
                <b-card class="mx-1">
                  <b-img fluid :src="porto" class="img-fluid" />
                </b-card>
              </div>
            </VueSlickCarousel>
          </b-col>
        </b-row>
      </b-col>
    </b-row>

    <b-row class="background-image programs-section" :style="{ backgroundImage: 'url(' + programsBackground + ')'}">
      <b-col class="h-75">
        <b-row>
          <b-col lg="3" class="d-none d-md-block d-lg-block">
            <hr class="programs-line mt-4">
          </b-col>
          <b-col lg="6" class="d-flex justify-content-center align-items-center text-center">
            <h1 class="heading-text-white mb-0 py-2">Upgrade Diri Bersama Program Kami</h1>
          </b-col>
          <b-col lg="3" class="d-none d-md-block d-lg-block">
            <hr class="programs-line mt-4">
          </b-col>
        </b-row>
      </b-col>
    </b-row>

    <b-row>
      <b-col>
        <b-row>
          <b-col lg="1" />
          <b-col lg="5" class="d-flex justify-content-center align-items-center">
            <b-img :src="programsIconSrc[0]" class="w-50" />
          </b-col>
          <b-col lg="6" class="pl-4 mt-2">
            <b-row>
              <b-col>
                <h4 class="programs-header-text">Bootcamp</h4>
              </b-col>
            </b-row>
            <b-row>
              <b-col>
                <p class="programs-desc-text">Intensive Live Class bersertifikat internasional <br class="d-none d-md-block"> level Professional Advanced</p>
              </b-col>
            </b-row>
            <b-row>
              <b-col>
                <b-row>
                  <b-col>
                    <span class="programs-perks-text d-flex align-items-center"><b-img :src="checkBoxIcon" class="mr-1 check-box-icon" /> Pembayaran bisa Tunai maupun Cicilan</span>
                  </b-col>
                </b-row>
                <b-row class="mt-1">
                  <b-col>
                    <span class="programs-perks-text d-flex align-items-center"><b-img :src="checkBoxIcon" class="mr-1 check-box-icon" /> 2 Sertifikat Internasional SolidWorks CSWPA</span>
                  </b-col>
                </b-row>
                <b-row class="mt-1">
                  <b-col>
                    <span class="programs-perks-text d-flex align-items-center"><b-img :src="checkBoxIcon" class="mr-1 check-box-icon" /> MechTech E-Certificates</span>
                  </b-col>
                </b-row>
                <b-row class="mt-1">
                  <b-col>
                    <span class="programs-perks-text d-flex align-items-center"><b-img :src="checkBoxIcon" class="mr-1 check-box-icon" /> 68 Jam Pembelajaran selama 4 bulan</span>
                  </b-col>
                </b-row>
                <b-row class="mt-1">
                  <b-col>
                    <span class="programs-perks-text d-flex align-items-center"><b-img :src="checkBoxIcon" class="mr-1 check-box-icon" /> Diajari dari nol</span>
                  </b-col>
                </b-row>
                <b-row class="mt-1">
                  <b-col>
                    <span class="programs-perks-text d-flex align-items-center"><b-img :src="checkBoxIcon" class="mr-1 check-box-icon" /> Project Exercise & Modul Pembelajaran</span>
                  </b-col>
                </b-row>
              </b-col>
            </b-row>
            <b-row class="mt-2">
              <b-col>
                <b-button size="lg" class="mt-2 btn-custom rounded-pill" href="https://mechtechidn.mayar.link/">
                  <h2 class="text-white">Daftar Sekarang</h2>
                </b-button>
              </b-col>
            </b-row>
          </b-col>
        </b-row>

        <b-row class="mt-5">
          <b-col />
        </b-row>

        <b-row class="mt-5">
          <b-col lg="2" sm="12" />
          <b-col class="d-sm-block d-md-none d-lg-none d-flex justify-content-center align-items-center">
            <b-img :src="programsIconSrc[1]" class="w-50" />
          </b-col>
          <b-col lg="5" sm="12" class="pl-4 mt-2">
            <b-row>
              <b-col>
                <h4 class="programs-header-text">Private Class</h4>
              </b-col>
            </b-row>
            <b-row>
              <b-col>
                <p class="programs-desc-text">Kelas private bersama tentor berpengalaman, <br class="d-none d-md-block"> belajar apapun yang kamu mau!</p>
              </b-col>
            </b-row>
            <b-row>
              <b-col>
                <b-row>
                  <b-col>
                    <span class="programs-perks-text d-flex align-items-center"><b-img :src="checkBoxIcon" class="mr-1 check-box-icon" /> Pendampingan pembuatan project</span>
                  </b-col>
                </b-row>
                <b-row class="mt-1">
                  <b-col>
                    <span class="programs-perks-text d-flex align-items-center"><b-img :src="checkBoxIcon" class="mr-1 check-box-icon" /> Cocok untuk kamu yang kesulitan <br> mengerjakan skripsi / project</span>
                  </b-col>
                </b-row>
                <b-row class="mt-1">
                  <b-col>
                    <span class="programs-perks-text d-flex align-items-center"><b-img :src="checkBoxIcon" class="mr-1 check-box-icon" /> Mengajarkan software teknik beserta <br> ilmu-ilmu keteknikannya</span>
                  </b-col>
                </b-row>
              </b-col>
            </b-row>
            <b-row class="mt-2">
              <b-col>
                <b-button size="lg" class="mt-2 btn-custom rounded-pill" variant="dark" href="https://wa.me/6282334433773" target="_blank">
                  <h2 class="text-white">Hubungi Kami</h2>
                </b-button>
              </b-col>
            </b-row>
          </b-col>
          <b-col class="d-none d-md-block d-lg-block h-100">
            <b-img :src="programsIconSrc[1]" class="w-50 mt-4" />
          </b-col>
        </b-row>

        <b-row class="mt-5">
          <b-col />
        </b-row>

        <b-row class="mt-5">
          <b-col lg="1" />
          <b-col lg="5" class="d-flex justify-content-center align-items-center">
            <b-img :src="programsIconSrc[2]" class="w-50" />
          </b-col>
          <b-col lg="6" class="pl-4 mt-2">
            <b-row>
              <b-col>
                <h4 class="programs-header-text">Partnership Class</h4>
              </b-col>
            </b-row>
            <b-row>
              <b-col>
                <p class="programs-desc-text">Membantu universitas, perusahaan, maupun <br class="d-none d-md-block"> lembaga lain dalam mengupgrade skill karyawannya</p>
              </b-col>
            </b-row>
            <b-row>
              <b-col>
                <b-row>
                  <b-col>
                    <span class="programs-perks-text d-flex align-items-center"><b-img :src="checkBoxIcon" class="mr-1 check-box-icon" /> Kelas tematik</span>
                  </b-col>
                </b-row>
                <b-row class="mt-1">
                  <b-col>
                    <span class="programs-perks-text d-flex align-items-center"><b-img :src="checkBoxIcon" class="mr-1 check-box-icon" /> Harga lebih terjangkau</span>
                  </b-col>
                </b-row>
                <b-row class="mt-1">
                  <b-col>
                    <span class="programs-perks-text d-flex align-items-center"><b-img :src="checkBoxIcon" class="mr-1 check-box-icon" /> Waktu fleksibel</span>
                  </b-col>
                </b-row>
              </b-col>
            </b-row>
            <b-row class="mt-2">
              <b-col>
                <b-button size="lg" class="mt-2 btn-custom rounded-pill" variant="dark" href="https://wa.me/6282334433773" target="_blank">
                  <h2 class="text-white">Hubungi Kami</h2>
                </b-button>
              </b-col>
            </b-row>
          </b-col>
        </b-row>
      </b-col>
    </b-row>

    <b-row class="mt-5">
      <b-col />
    </b-row>
    <b-row class="mt-5">
      <b-col />
    </b-row>

    <b-row class="mt-5 pt-5 reviews-section" :style="{ backgroundImage: 'url(' + faqBackground + ')'}">
      <b-col lg="1" />
      <b-col lg="5" class="pl-4">
        <b-row class="mt-5">
          <b-col>
            <h1 class="reviews-header-text">Kata Sobat Techie <br> Tentang MechTech Indonesia</h1>
          </b-col>
        </b-row>
        <b-row class="mt-2">
          <b-col>
            <p class="reviews-desc-text">Fokus utama kami adalah memberikan experience <br> belajar terbaik untuk student kami.</p>
          </b-col>
        </b-row>
        <b-row>
          <b-col>
            <b-img :src="reviewsImage" class="w-75" />
          </b-col>
        </b-row>
      </b-col>
      <b-col lg="6" class="px-2">
        <b-row>
          <b-col lg="5">
            <b-row>
              <b-col>
                <b-card class="review-card">
                  <b-row>
                    <b-col lg="3" class="d-flex justify-content-center align-items-center">
                      <b-img rounded="circle" :src="reviewSrc[0]" class="review-card-participant-photo" />
                    </b-col>
                    <b-col lg="9">
                      <h3 class="text-white">Hildan Fahrizal Nur Faurizki</h3>
                      <span>Universitas Negeri Malang</span>
                    </b-col>
                  </b-row>
                  <b-row class="mt-2">
                    <b-col>
                      <p class="review-card-text">
                        Kelas Fundamentalnya mantap! 
                        Tentornya helpfull banget, belajar sambil diselingi tambahan pengetahuan tentang 
                        software teknik yang lain, maupun industri teknik sendiri. Cocok banget buat 
                        yang yang mau belajar dari 0 sampai jago!
                      </p>
                    </b-col>
                  </b-row>
                </b-card>
              </b-col>
            </b-row>
            <b-row>
              <b-col>
                <b-card class="review-card">
                  <b-row>
                    <b-col lg="3" class="d-flex justify-content-center align-items-center">
                      <b-img rounded="circle" :src="reviewSrc[1]" class="review-card-participant-photo" />
                    </b-col>
                    <b-col lg="9">
                      <h3 class="text-white">Natasya Habibah</h3>
                      <span>Universitas Pertahanan</span>
                    </b-col>
                  </b-row>
                  <b-row class="mt-2">
                    <b-col>
                      <p class="review-card-text">
                        Setiap pelatihan di MechTech bikin belajar hal-hal baru yang sebelumnya terasa sulit jadi 
                        lebih mudah. Belajar hal basic yang gitu-gitu aja jadi lebih menarik karena Mentor-nya
                        TOP dan ada grup diskusinya juga. Jadi bisa nambah teman diluar kampus!
                      </p>
                    </b-col>
                  </b-row>
                </b-card>
              </b-col>
            </b-row>
          </b-col>
          <b-col lg="5">
            <b-row class="mt-5">
              <b-col>
                <b-card class="review-card">
                  <b-row>
                    <b-col lg="3" class="d-flex justify-content-center align-items-center">
                      <b-img rounded="circle" :src="reviewSrc[2]" class="review-card-participant-photo" />
                    </b-col>
                    <b-col lg="9">
                      <h3 class="text-white">Daniar Agistya Wardhana</h3>
                      <span>Institut Teknologi Sepuluh November</span>
                    </b-col>
                  </b-row>
                  <b-row class="mt-2">
                    <b-col>
                      <p class="review-card-text">
                        Kelas Fundamentalnya mantap! 
                        Rangkaian kelas disini juara banget sih! 
                        Mentor sabar & telaten setiap menjawab pertanyaan bahkan
                        diluar kelas juga. Penguasaan setiap materinya juga TOP banget! 
                        Good job, MechTech!
                      </p>
                    </b-col>
                  </b-row>
                </b-card>
              </b-col>
            </b-row>
            <b-row>
              <b-col>
                <b-card class="review-card">
                  <b-row>
                    <b-col lg="3" class="d-flex justify-content-center align-items-center">
                      <b-img rounded="circle" :src="reviewSrc[3]" class="review-card-participant-photo" />
                    </b-col>
                    <b-col lg="9">
                      <h3 class="text-white">Mogah Sagacious Saragih</h3>
                      <span>Institut Teknologi Sepuluh November</span>
                    </b-col>
                  </b-row>
                  <b-row class="mt-2">
                    <b-col>
                      <p class="review-card-text">
                        Belajar dengan MechTech sangat membuat diri Saya improve di banyak hal, terutama dalam desain & simulasi di aplikasinya,
                        tidak lupa juga teori-teori yang mendukungnya diberikan dengan cukup lengkap sehingga sangat membantu
                      </p>
                    </b-col>
                  </b-row>
                </b-card>
              </b-col>
            </b-row>
          </b-col>
        </b-row>
      </b-col>
    </b-row>

    <b-row class="pb-5 animated-section faq-gradient">
      <b-col class="mx-5">
        <FrequentlyAsked />
      </b-col>
    </b-row>

    <b-row class="background-image upper-footer" :style="{ backgroundImage: 'url(' + upperFooterBackground + ')'}">
      <b-col>
        <b-row class="mt-5">
          <b-col lg="6" md="6" sm="6" class="d-flex justify-content-center align-items-center">
            <b-img :src="phoneMockup" class="w-50" />
          </b-col>
          <b-col lg="6" md="6" sm="6" class="d-flex align-items-center">
            <b-row class="pl-2 mt-2">
              <b-col>
                <h1 class="upper-footer-text-sm">Jadilah Yang Pertama Tahu</h1>
                <h1 class="upper-footer-text-lg">Follow Instagram kami</h1>
                <b-button size="lg" class="mt-2 rounded-pill" variant="light" href="https://www.instagram.com/mechtech.class/" target="_blank">
                  <h1 class="follow-button-text">Follow >></h1>
                </b-button>
              </b-col>
            </b-row>
          </b-col>
        </b-row>
      </b-col>
    </b-row>

    <b-row class="pt-5 pb-3" style="background-color: #0E224C">
      <b-col class="text-center">
        <b-row class="mt-5">
          <b-col />
        </b-row>
        <b-row class="mt-5">
          <b-col />
        </b-row>
        <p style="color: white; font-weight: bold;">&copy; 2024 MechTech Indonesia. All rights reserved.</p>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import FrequentlyAsked from '@core/components/FrequentlyAsked.vue'
import VueSlickCarousel from 'vue-slick-carousel'
import 'vue-slick-carousel/dist/vue-slick-carousel.css'
import 'vue-slick-carousel/dist/vue-slick-carousel-theme.css'

export default {
  name: 'LandingPage',
  components: {
    FrequentlyAsked,
    VueSlickCarousel
  },
  data() {
    return {
      landingBackground: require('@/assets/images/mechtech/Revisi-Atas-2.png'),
      commentBackground: require('@/assets/images/mechtech/Revisi-Testimoni.png'),
      renderImage: require('@/assets/images/mechtech/render-image.png'),
      programsBackground: require('@/assets/images/mechtech/programs-bg.png'),
      upperFooterBackground: require('@/assets/images/mechtech/upper-footer-bg.png'),
      faqBackground: require('@/assets/images/mechtech/faq-bg.png'),
      reviewsImage: require('@/assets/images/mechtech/reviews-image.png'),
      valuePropositionSrc: [
        require('@/assets/images/mechtech/value-1.png'),
        require('@/assets/images/mechtech/value-2.png'),
        require('@/assets/images/mechtech/value-3.png'),
        require('@/assets/images/mechtech/value-4.png'),
        require('@/assets/images/mechtech/value-5.png'),
        require('@/assets/images/mechtech/value-6.png'),
      ],
      checkBoxIcon: require('@/assets/images/mechtech/check-box-icon.png'),
      programsIconSrc: [
        require('@/assets/images/mechtech/programs-icon-1.png'),
        require('@/assets/images/mechtech/programs-icon-2.png'),
        require('@/assets/images/mechtech/programs-icon-3.png'),
      ],
      phoneMockup: require('@/assets/images/mechtech/phone-mockup.png'),
      uniCarouselSetting: {
        dots: false,
        focusOnSelect: false,
        infinite: true,
        speed: 500,
        slidesToShow: 7,
        slidesToScroll: 7,
        touchThreshold: 5,
        responsive: [
          {
            breakpoint: 600,
            settings: {
              slidesToShow: 4,
              slidesToScroll: 4,
            }
          },
          {
            breakpoint: 430,
            settings: {
              slidesToShow: 2,
              slidesToScroll: 2
            }
          }
        ]
      },
      partnerCarouselSetting: {
        dots: false,
        arrows: true,
        focusOnSelect: true,
        infinite: true,
        speed: 500,
        slidesToShow: 4,
        slidesToScroll: 4,
        touchThreshold: 5,
        responsive: [
          {
            breakpoint: 600,
            settings: {
              slidesToShow: 3,
              slidesToScroll: 3,
            }
          },
          {
            breakpoint: 430,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1
            }
          }
        ]
      },
      portoCarouselSetting: {
        dots: false,
        arrows: true,
        focusOnSelect: true,
        infinite: true,
        speed: 500,
        slidesToShow: 4,
        slidesToScroll: 4,
        touchThreshold: 5,
        responsive: [
          {
            breakpoint: 600,
            settings: {
              slidesToShow: 3,
              slidesToScroll: 3,
            }
          },
          {
            breakpoint: 430,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1
            }
          }
        ]
      },
      univLogoSr: [
        require('@/assets/images/mechtech/univ-logo/logo-itb.png'),
        require('@/assets/images/mechtech/univ-logo/logo-its.png'),
        require('@/assets/images/mechtech/univ-logo/logo-ui.png'),
        require('@/assets/images/mechtech/univ-logo/logo-ugm.png'),
        require('@/assets/images/mechtech/univ-logo/logo-ub.png'),
        require('@/assets/images/mechtech/univ-logo/logo-binus.png'),
        require('@/assets/images/mechtech/univ-logo/logo-lsu.png'),
        require('@/assets/images/mechtech/univ-logo/logo-pukyong.png'),
        require('@/assets/images/mechtech/univ-logo/logo-umy.png'),
        require('@/assets/images/mechtech/univ-logo/logo-undip.png'),
        require('@/assets/images/mechtech/univ-logo/logo-pens.png'),
        require('@/assets/images/mechtech/univ-logo/logo-upnvj.png'),
        require('@/assets/images/mechtech/univ-logo/logo-unud.png'),
        require('@/assets/images/mechtech/univ-logo/logo-north.png'),
        require('@/assets/images/mechtech/univ-logo/logo-gundar.png'),
        require('@/assets/images/mechtech/univ-logo/logo-ageng.png'),
        require('@/assets/images/mechtech/univ-logo/logo-polinema.png'),
        require('@/assets/images/mechtech/univ-logo/logo-pnj.png'),
      ],
      partnerLogoSrc: [
        require('@/assets/images/mechtech/partner-logo/partner-smart.png'),
        require('@/assets/images/mechtech/partner-logo/partner-sejuta-cita.png'),
        require('@/assets/images/mechtech/partner-logo/partner-glints.png'),
        require('@/assets/images/mechtech/partner-logo/partner-onesia.png'),
        require('@/assets/images/mechtech/partner-logo/partner-dibimbing.png'),
        require('@/assets/images/mechtech/partner-logo/partner-vascomm.png'),
        require('@/assets/images/mechtech/partner-logo/partner-tsn.png'),
        require('@/assets/images/mechtech/partner-logo/partner-supraco.png'),
      ],
      portoSrc: [
        require('@/assets/images/mechtech/porto-certificate/porto-1.png'),
        require('@/assets/images/mechtech/porto-certificate/porto-2.png'),
        require('@/assets/images/mechtech/porto-certificate/porto-3.png'),
        require('@/assets/images/mechtech/porto-certificate/porto-4.png'),
        require('@/assets/images/mechtech/porto-certificate/porto-5.png'),
        require('@/assets/images/mechtech/porto-certificate/porto-6.png'),
        require('@/assets/images/mechtech/porto-certificate/porto-7.png'),
        require('@/assets/images/mechtech/porto-certificate/porto-8.png'),
        require('@/assets/images/mechtech/porto-certificate/porto-9.png'),
        require('@/assets/images/mechtech/porto-certificate/porto-10.png'),
      ],
      reviewSrc: [
        require('@/assets/images/mechtech/review-image/logo-um-smaller.png'),
        require('@/assets/images/mechtech/review-image/logo-pertahanan-smaller.png'),
        require('@/assets/images/mechtech/review-image/logo-its-smaller.png'),
        require('@/assets/images/mechtech/review-image/logo-its-smaller.png'),
      ],
      slide: 0,
    }
  },
  methods: {
    onSlideStart(slide) {
      this.sliding = true
    },
    onSlideEnd(slide) {
      this.sliding = false
    }
  }
}
</script>

<style lang="scss" scoped>
@import "~@core/scss/base/themes/bordered-layout.scss";

.no-scroll-x {
  overflow-x: clip;
}

.jumbotron {
  height: 100vh;
}

.jumbotron-header-text {
  font-weight: bold;
  font-size: 1.5rem;
  color: white;

  @include media-breakpoint-up(sm) {
    font-size: 1.5rem;
  }
  @include media-breakpoint-up(md) {
    font-size: 4.4rem;
  }
  @include media-breakpoint-up(lg) {
    font-size: 4.4rem;
  }
  @include media-breakpoint-up(xl) {
    font-size: 4.4rem;
  }
}

.jumbotron-lead-text {
  text-align: left;
  color: white;
  font-size: 1.25rem;
  line-height: 1.25;

  @include media-breakpoint-up(sm) {
    font-size: 1.25rem;
  }
  @include media-breakpoint-up(md) {
    font-size: 2.25rem;
  }
  @include media-breakpoint-up(lg) {
    font-size: 2.25rem;
  }
  @include media-breakpoint-up(xl) {
    font-size: 2.25rem;
  }
}

.jumbotron-button-text {
  font-size: 1.5rem;

  @include media-breakpoint-up(sm) {
    font-size: 1.5rem;
  }
  @include media-breakpoint-up(md) {
    font-size: 2rem;
  }
  @include media-breakpoint-up(lg) {
    font-size: 2rem;
  }
  @include media-breakpoint-up(xl) {
    font-size: 2rem;
  }
}

.responsive-image {
  max-width: 100%;
  height: auto;
}

@media (max-width: 576px) {
  .responsive-image {
    width: 40%;
  }
}

.animated-section {
  animation: fadeInUp 1s ease-out;
}

.animated-title {
  animation: fadeIn 1.5s ease-out;
}

@keyframes fadeInUp {
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.btn-custom {
    background-color: #0E224C !important;
    color: white;
    border: none;
}

.btn-custom:hover {
    background-color: #091836 !important; /* Darken the color by 10% */
    color: white;
}

.heading-text-underline {
  font-weight: 800;
  font-size: 2rem;
  color: black;

  @include media-breakpoint-up(sm) {
    font-size: 2rem;
  }
  @include media-breakpoint-up(md) {
    font-size: 2.8rem;
  }
  @include media-breakpoint-up(lg) {
    font-size: 2.8rem;
  }
  @include media-breakpoint-up(xl) {
    font-size: 2.8rem;
  }
}

.carousel-image {
  width: 100%;
  height: auto;
}

.check-box-icon {
  height: 28px;
}

.programs-line {
    height: 2px;
    background-color: #C4C4C4;
    border: none;
}

.institution-line {
    height: 5px;
    background-color: #990000;
    border: none;
}

// ====================== BACKGROUND IMAGE SETTINGS ======================

.top-gradient {
  background: linear-gradient(to bottom, #990000, #26385E);
}

.faq-gradient {
  background: linear-gradient(to bottom, #26385E, #3F5687);
}

.background-image {
  background-size: 100% 100%;
  background-repeat: no-repeat;
}

.upper-footer {
  background-color: #3F5687;
  height: 550px;
}

.programs-section {
  height: 250px;
}

.reviews-section {
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center bottom;
  padding-bottom: 250px;
}

// ====================== END OF BACKGROUND IMAGE SETTINGS ======================


// ====================== TEXT STYLES ======================
.heading-text-white {
  font-weight: 600;
  font-size: 2rem;
  color: white;

  @include media-breakpoint-up(sm) {
    font-size: 2rem;
  }
  @include media-breakpoint-up(md) {
    font-size: 3.4rem;
  }
  @include media-breakpoint-up(lg) {
    font-size: 3.4rem;
  }
  @include media-breakpoint-up(xl) {
    font-size: 3.4rem;
  }
}

.value-proposition-text {
  font-size: 1.25rem;
  color: white;
  font-weight: 600;
  line-height: 1.25;

  @include media-breakpoint-up(sm) {
    font-size: 1.25rem;
  }
  @include media-breakpoint-up(md) {
    font-size: 2rem;
  }
  @include media-breakpoint-up(lg) {
    font-size: 2rem;
  }
  @include media-breakpoint-up(xl) {
    font-size: 2rem;
  }
}

h2 {
  font-size: 1.25rem;

  @include media-breakpoint-up(sm) {
    font-size: 1.25rem;
  }
  @include media-breakpoint-up(md) {
    font-size: 1.75rem;
  }
  @include media-breakpoint-up(lg) {
    font-size: 1.75rem;
  }
  @include media-breakpoint-up(xl) {
    font-size: 1.75rem;
  }
}

.programs-header-text {
  font-size: 2rem;
  color: #0E224C;
  font-weight: bold;

  @include media-breakpoint-up(sm) {
    font-size: 2rem;
  }
  @include media-breakpoint-up(md) {
    font-size: 2.5rem;
  }
  @include media-breakpoint-up(lg) {
    font-size: 2.5rem;
  }
  @include media-breakpoint-up(xl) {
    font-size: 2.5rem;
  }
}

.programs-desc-text {
  font-size: 1.3rem;
  color: #0E224C;
  font-weight: 500;
  line-height: 1.25;

  @include media-breakpoint-up(sm) {
    font-size: 1.3rem;
  }
  @include media-breakpoint-up(md) {
    font-size: 1.8rem;
  }
  @include media-breakpoint-up(lg) {
    font-size: 1.8rem;
  }
  @include media-breakpoint-up(xl) {
    font-size: 1.8rem;
  }
}

.programs-perks-text {
  font-size: 1.3rem;
  color: #6E6E6E;
  font-weight: 500;
  line-height: 1.25;

  @include media-breakpoint-up(sm) {
    font-size: 1.3rem;
  }
  @include media-breakpoint-up(md) {
    font-size: 1.5rem;
  }
  @include media-breakpoint-up(lg) {
    font-size: 1.5rem;
  }
  @include media-breakpoint-up(xl) {
    font-size: 1.5rem;
  }
}

.reviews-header-text {
  font-size: 32px;
  color: #0E224C;
  font-weight: bold;
  line-height: 1.25;
}

.reviews-desc-text {
  font-size: 22px;
  color: #686868;
  font-weight: 500;
  line-height: 1.25;
}

.upper-footer-text-sm {
  font-weight: 600;
  font-size:  1.5rem;
  color: white;
  line-height: 1.25;

  @include media-breakpoint-up(sm) {
    font-size: 1.5rem;
  }
  @include media-breakpoint-up(md) {
    font-size: 3rem;
  }
  @include media-breakpoint-up(lg) {
    font-size: 3rem;
  }
  @include media-breakpoint-up(xl) {
    font-size: 3rem;
  }
}
.upper-footer-text-lg {
  font-weight: 600;
  font-size: 2.5rem;
  color: white;
  line-height: 1.25;

  @include media-breakpoint-up(sm) {
    font-size: 2.5rem;
  }
  @include media-breakpoint-up(md) {
    font-size: 4.5rem;
  }
  @include media-breakpoint-up(lg) {
    font-size: 4.5rem;
  }
  @include media-breakpoint-up(xl) {
    font-size:4.5rem;
  }
}

.follow-button-text {
  font-weight: 600;
  color: #0E224C;
}

// ====================== END OF TEXT STYLES ======================


// ====================== CAROUSEL STYLES ======================
.partner-images {
  max-width: 100%;
  height: 125px;
}

.porto-images {
  max-width: 100%;
  height: 280px;
}

.slick-prev:before, .slick-next:before {
  font-size: 30px !important;
  color: #C4C4C4;
}
// ====================== END OF CAROUSEL STYLES ======================


.review-card {
  background-color: #46629C;
  color: white;
}
.review-card-text {
  font-size: 17px;
  line-height: 1.25;
}
.review-card-participant-photo {
  width: 75px;
}
</style>