<template>
  <b-card class="rounded-lg">
    <b-card-body>
      <b-row>
        <b-col lg="5" class="d-flex align-items-center">
          <b-row>
            <b-col>
              <h1 class="faq-header">Frequently Asked Question</h1>
              <p class="faq-desc mt-2">Berikut beberapa pertanyaan yang mungkin ingin kamu tanyakan ke kami, kalau masih penasaran langsung saja hubungi admin!</p>
              <b-button size="lg" class="mt-2 btn-custom rounded-pill" variant="dark" href="https://wa.me/6282334433773" target="_blank">
                <h2 class="text-white font-weight-bold">Hubungi Admin</h2>
              </b-button>
            </b-col>
          </b-row>
        </b-col>
        <b-col lg="7" class="faq-right-content my-2">
          <div class="accordion" role="tablist">
            <b-row>
              <b-col cols="12" class="p-1" role="tab">
                <h5 v-b-toggle="'accordion-1'" class="mb-0 faq-points-header" style="cursor: pointer;">
                  Kapan Kelas dilaksanakan?
                </h5>
                <hr class="mb-0">
              </b-col>
              <b-collapse id="accordion-1" accordion="my-accordion" role="tabpanel" class="p-1">
                <b-row>
                  <b-col>
                    <p class="faq-points-text">
                      🕘 Setiap Sabtu & Minggu jam 09.00 WIB untuk kelas fundamental <br>
                      🕖 Setiap Sabtu & Minggu jam 19.00 WIB untuk kelas sertifikasi <br>
                      🕐 Setiap Sabtu jam 13.00 WIB untuk kelas freelancing <br> <br>

                      Untuk informasi tanggal, bisa mengunjungi <a href="http://mechtechidn.mayar.link" target="_blank" rel="noopener noreferrer"> Link Berikut </a>
                    </p>
                  </b-col>
                </b-row>
              </b-collapse>
            </b-row>

            <b-row>
              <b-col cols="12" class="p-1" role="tab">
                <h5 v-b-toggle="'accordion-2'" class="mb-0 faq-points-header" style="cursor: pointer;">
                  Apakah kelas Offline atau Online?
                </h5>
                <hr class="mb-0">
              </b-col>
              <b-collapse id="accordion-2" accordion="my-accordion" role="tabpanel" class="p-1">
                <b-row>
                  <b-col>
                    <p class="faq-points-text">
                      Kelas dilakukan online via zoom meeting
                    </p>
                  </b-col>
                </b-row>
              </b-collapse>
            </b-row>

            <b-row>
              <b-col cols="12" class="p-1" role="tab">
                <h5 v-b-toggle="'accordion-3'" class="mb-0 faq-points-header" style="cursor: pointer;">
                  Apakah tidak apa-apa menggunakan software crack?
                </h5>
                <hr class="mb-0">
              </b-col>
              <b-collapse id="accordion-3" accordion="my-accordion" role="tabpanel" class="p-1">
                <b-row>
                  <b-col>
                    <p class="faq-points-text">
                      Software crack atau asli tidak akan mengganggu jalannya kelas namun kami tetap merekomendasikan menggunakan software asli
                    </p>
                  </b-col>
                </b-row>
              </b-collapse>
            </b-row>

            <b-row>
              <b-col cols="12" class="p-1" role="tab">
                <h5 v-b-toggle="'accordion-4'" class="mb-0 faq-points-header" style="cursor: pointer;">
                  Apakah akan dibantu install software?
                </h5>
                <hr class="mb-0">
              </b-col>
              <b-collapse id="accordion-4" accordion="my-accordion" role="tabpanel" class="p-1">
                <b-row>
                  <b-col>
                    <p class="faq-points-text">
                      Cara menginstal software bisa dilihat di <a href="https://bit.ly/45w0tjm" target="_blank" rel="noopener noreferrer">sini</a>
                    </p>
                  </b-col>
                </b-row>
              </b-collapse>
            </b-row>

            <b-row>
              <b-col cols="12" class="p-1" role="tab">
                <h5 v-b-toggle="'accordion-5'" class="mb-0 faq-points-header" style="cursor: pointer;">
                  Aplikasi apa saja yang digunakan dan minimal versi berapa?
                </h5>
                <hr class="mb-0">
              </b-col>
              <b-collapse id="accordion-5" accordion="my-accordion" role="tabpanel" class="p-1">
                <b-row>
                  <b-col>
                    <p class="faq-points-text">
                      AutoCAD Mechanical 2020 <br>
                      SolidWorks 2020 <br>
                      ANSYS 2020 <br>
                    </p>
                  </b-col>
                </b-row>
              </b-collapse>
            </b-row>

            <b-row>
              <b-col cols="12" class="p-1" role="tab">
                <h5 v-b-toggle="'accordion-6'" class="mb-0 faq-points-header" style="cursor: pointer;">
                  Apa spek minimum Laptop/PC yang dibutuhkan untuk mengikuti pelatihan?
                </h5>
                <hr class="mb-0">
              </b-col>
              <b-collapse id="accordion-6" accordion="my-accordion" role="tabpanel" class="p-1">
                <b-row>
                  <b-col>
                    <p class="faq-points-text">
                      AutoCAD: <a href="https://knowledge.autodesk.com/support/autocad/troubleshooting/caas/sfdcarticles/sfdcarticles/System-requirements-for-AutoCAD-2022-including-Specialized-Toolsets.html" target="_blank" rel="noopener noreferrer">Minimum Requirements</a> <br>

                      Solidworks: <a href="https://www.solidworks.com/support/system-requirements" target="_blank" rel="noopener noreferrer">Minimum Requirements</a> <br>

                      ANSYS:  <a href="https://install.simutechgroup.com/info-ansys-hardware-recommendations" target="_blank" rel="noopener noreferrer">Minimum Requirements</a>
                    </p>
                  </b-col>
                </b-row>
              </b-collapse>
            </b-row>

            <b-row>
              <b-col cols="12" class="p-1" role="tab">
                <h5 v-b-toggle="'accordion-7'" class="mb-0 faq-points-header" style="cursor: pointer;">
                  Di luar kelas boleh konsultasi dan tanya-tanya?
                </h5>
                <hr class="mb-0">
              </b-col>
              <b-collapse id="accordion-7" accordion="my-accordion" role="tabpanel" class="p-1">
                <b-row>
                  <b-col>
                    <p class="faq-points-text">
                      Boleh, grup telegram digunakan sebagai forum diskusi. Apabila ada yang kurang dimengerti bisa ditanyakan melalui grup. Tentor akan menjawab pertanyaan Anda. 
                    </p>
                  </b-col>
                </b-row>
              </b-collapse>
            </b-row>

            <b-row>
              <b-col cols="12" class="p-1" role="tab">
                <h5 v-b-toggle="'accordion-8'" class="mb-0 faq-points-header" style="cursor: pointer;">
                  Apabila saya gagal dalam sertifikasi, bagaimana cara mendapatkan garansi?
                </h5>
                <hr class="mb-0">
              </b-col>
              <b-collapse id="accordion-8" accordion="my-accordion" role="tabpanel" class="p-1">
                <b-row>
                  <b-col>
                    <p class="faq-points-text">
                      Garansi (sertifikasi ulang tanpa biaya tambahan) akan diberlakukan apabila Anda memenuhi kriteria sebagai berikut: <br>
                      <ul>
                        <li>Kelengkapan dan kesesuaian tugas 100%</li>
                        <li>Kehadiran 100%</li>
                      </ul>
                    </p>
                  </b-col>
                </b-row>
              </b-collapse>
            </b-row>
          </div>
        </b-col>
      </b-row>
    </b-card-body>
  </b-card>
</template>

<script>
export default {
  data() {
    return {
      text: `
        Anim pariatur cliche reprehenderit, enim eiusmod high life accusamus terry
        richardson ad squid. 3 wolf moon officia aute, non cupidatat skateboard dolor
        brunch. Food truck quinoa nesciunt laborum eiusmod. Brunch 3 wolf moon
        tempor, sunt aliqua put a bird on it squid single-origin coffee nulla
        assumenda shoreditch et. Nihil anim keffiyeh helvetica, craft beer labore
        wes anderson cred nesciunt sapiente ea proident. Ad vegan excepteur butcher
        vice lomo. Leggings occaecat craft beer farm-to-table, raw denim aesthetic
        synth nesciunt you probably haven't heard of them accusamus labore VHS.
      `,
      accordions: [
        { id: 1, title: "Kapan Kelas dilaksanakan?", text: '' },
        { id: 2, title: "Apakah kelas Offline atau Online?", text: "Content for Accordion 2" },
        { id: 3, title: "Apakah tidak apa-apa menggunakan software crack?", text: "Content for Accordion 3" },
        { id: 4, title: "Apakah akan dibantu install software?", text: "I start opened because visible is true" },
        { id: 5, title: "Apa spek minimum Laptop/PC yang dibutuhkan untuk mengikuti pelatihan?", text: "Content for Accordion 2" },
        { id: 6, title: "Aplikasi apa saja yang digunakan dan minimal versi berapa?", text: "Content for Accordion 3" },
        { id: 7, title: "Di luar kelas boleh konsultasi dan tanya-tanya?", text: "I start opened because visible is true" },
        { id: 8, title: "Apabila saya gagal dalam sertifikasi, bagaimana cara mendapatkan garansi?", text: "Content for Accordion 2" },

      ]
    }
  }
}
</script>

<style lang="scss" scoped>
@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/mixins";

  .faq-right-content {
    border-left: 3px solid #C9C9C9;
  }

  .faq-header {
    font-size: 1.8rem;
    color: #0E224C;
    font-weight: 700;
    line-height: 1.25;

    @include media-breakpoint-up(sm) {
      font-size: 1.8rem;
    }
    @include media-breakpoint-up(md) {
      font-size: 2.5rem;
    }
    @include media-breakpoint-up(lg) {
      font-size: 2.5rem;
    }
    @include media-breakpoint-up(xl) {
      font-size: 2.5rem;
    }
  }

  .faq-desc {
    font-size: 1.5rem;
    color: #686868;
    line-height: 1.25;

    @include media-breakpoint-up(sm) {
      font-size: 1.5rem;
    }
    @include media-breakpoint-up(md) {
      font-size: 1.75rem;
    }
    @include media-breakpoint-up(lg) {
      font-size: 1.75rem;
    }
    @include media-breakpoint-up(xl) {
      font-size: 1.75rem;
    }
  }

  h2 {
    font-size: 1.25rem;

    @include media-breakpoint-up(sm) {
      font-size: 1.25rem;
    }
    @include media-breakpoint-up(md) {
      font-size: 1.75rem;
    }
    @include media-breakpoint-up(lg) {
      font-size: 1.75rem;
    }
    @include media-breakpoint-up(xl) {
      font-size: 1.75rem;
    }
  }

  .btn-custom {
      background-color: #0E224C !important;
      color: white;
      border: none;
  }

  .btn-custom:hover {
      background-color: #091836 !important; /* Darken the color by 10% */
      color: white;
  }

  .faq-points-header {
    font-size: 1.25rem;
    line-height: 1.25;

    @include media-breakpoint-up(sm) {
      font-size: 1.25rem;
    }
    @include media-breakpoint-up(md) {
      font-size: 1.75rem;
    }
    @include media-breakpoint-up(lg) {
      font-size: 1.75rem;
    }
    @include media-breakpoint-up(xl) {
      font-size: 1.75rem;
    }
  }
  .faq-points-text {
    font-size: 1rem;
    line-height: 1.25;

    @include media-breakpoint-up(sm) {
      font-size: 1rem;
    }
    @include media-breakpoint-up(md) {
      font-size: 1.25rem;
    }
    @include media-breakpoint-up(lg) {
      font-size: 1.25rem;
    }
    @include media-breakpoint-up(xl) {
      font-size: 1.25rem;
    }
  }
</style>
